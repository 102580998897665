<template>
    <div style="text-align: center; height: 100%">
        <div class="jumbotron" style="background: rgb(249, 246, 246)">
            <div class="container register">
                <div class="row">
                    <div class="col-lg-6 col-md-8 col-sm-12 offset-lg-3 offset-md-2">
                        <h2 style="color: #5457c1">DoorHacker PRO</h2>
                        <h6 style="margin-top: 3rem">Copy below code and paste in the popup to upgrade to PRO</h6>
                        <div style="margin-top: 1rem">In the future, you can find this code in the payment invoice</div>
                        <h5 style="margin-top: 2rem; margin-bottom: 4rem">
                            <span style="border-style: solid; padding: 10px; border-radius: 10px">{{code}}</span>
                            <span class="col-md-1 col-2">
                                <a href="#" v-on:click="doCopy"><font-awesome-icon style="font-size: 32px;" icon="copy" /></a>
                            </span>
                        </h5>
                        <img src="./resources/activateCode.webp" alt="cash flow calculator rental" />
                        <div style="margin-top: 4rem;">If you have any questions, please feel free to contact us at <a href="https://www.doorhacker.com/#contact">ceo@doorhacker.com</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'PaymentConfirmation',
        data() {
            return {
                code: 'E9zKq2cN5mV8hR7J'
            }
        },
        methods: {
            doCopy() {
                const textarea = document.createElement("textarea");
                textarea.value = this.code;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);
            }
        }
    }
</script>
