<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-8></blog-post-8>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost8 from '../blog-components/BlogPost8';


export default {
  name: 'BlogPostView8',
  components: {
    BlogPost8,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Utah: Unlocking Real Estate Potential",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Utah offers promising opportunities due to its strong economy, population growth, and diverse market. Cities like Salt Lake City, Provo, and Park City present attractive prospects for investors. Salt Lake City, as the capital, benefits from a robust job market and a range of investment options in residential and commercial properties."},
      ]
    }
  }
}
</script>
