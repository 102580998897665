<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img width="120px" src="../resources/doorhacker-logo.png" alt="DoorHacker zillow extension"/>
                            </a>
                        </div>
                        <p style="font-weight: 600">Find cash flow on Zillow</p>
                        <p>Get rents in one click and screen properties with the highest returns</p>
                    </div>
                </div>
              

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="list">
                            <li><router-link class :to="{ name: 'Home', params: { location: 'Solutions' }}">Solutions</router-link></li>
                            <li><router-link class :to="{ name: 'Home', params: { location: 'Pricing' }}">Pricing</router-link></li>

                            <li><a href="/blog/1">Blog</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>
                        <ul class="list">
                            <li><a href="/RE_Terms_of_Service.pdf">Terms of Service</a></li>
                            <li><a href="/RE_Privacy_Policy.pdf">Privacy Policy</a></li>
                            <li><a href="/RE_Website_Terms_and_Conditions.pdf">Terms and Conditions</a></li>

                            <li><router-link class :to="{ name: 'Home', params: { location: 'Contact' }}">Contact</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2023 DoorHacker. All Rights Reserved</p>
                        <div style="text-align: justify; text-justify: inter-word;">
                            <p style="font-size: 10px">Doorhacker is an independent tool that enhances your experience on Zillow and is not affiliated with or endorsed by Zillow.</p>
                            <p style="font-size: 10px">All estimates are based on publicly available data, conducted for basic pre-screening purposes and might not reflect the actual condition or changing market situation. We encorage you to conduct an independent in-depth analysis to include changing market situation, condition of the property and additional information from viewings, survey, legal checks or other sources. DoorHacker does not warrant the accuracy or validity of the information or data, and hereby disclaims any liability to any person for any loss or damage caused by errors or omissions. DoorHacker is also not responsible for any material or information contained in the sites where data originate from.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img src="../resources/map.png" alt="Zillow rent returns" class="map">
        <div class="shape1"><img src="../resources/shape1.png" alt="zillow analysis"></div>
        <div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="zillow cash flow"></div>

        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><vue-feather type="arrow-up"></vue-feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'ExternalFooterGreenCard',
    components: { BackToTop }
}
</script>
