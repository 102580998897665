<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-12></blog-post-12>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost12 from '../blog-components/BlogPost12';


export default {
  name: 'BlogPostView12',
  components: {
    BlogPost12,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "North Carolina: Unearthing Promising Real Estate Markets",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in North Carolina presents a compelling opportunity due to its strong economy, population growth, and diverse market. Cities like Charlotte, Raleigh, and Asheville offer favorable prospects for investors."},
      ]
    }
  }
}
</script>
