<template>
    <!-- Start Design & Development Services -->
    <section class="services-area ptb-80" style="background: white; padding-bottom: 40px;">
        <div id="solutions"/>
        <div class="container">
            <div class="section-title">
                <h2>Powerful Zillow Analytics</h2>

<!-- first green bar--> 
                <h5 style="margin-top: 10px">cities covered</h5>
                <div class="bar"></div>
                <div class="paragraph">
                    <p>Can't find the city you invest in?</p>
                    <p><a href="/#contact" style="text-decoration: underline">CONTACT US</a> to get notified once it is available.</p>
                    <h2 style="text-align: center; color: #6084a4; margin-top: 20px; margin-bottom: 0px">&#8681;</h2>

                    <div class="star-section">
                        <div class="row">

        <!-- small screens-->                        
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Montgomery, AL</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Mesa, AZ</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Phoenix, AZ</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Concord, CA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Hayward, CA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">San Bruno, CA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">San Leandro, CA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Tracy, CA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">New Haven, CT</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Bradenton, FL</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Jacksonville, FL</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Alpharetta, GA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Atlanta, GA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Indianapolis, IN</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Charlotte, NC</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Austin, TX</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">College Station, Tx</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Dallas, TX</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Houston, TX</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Bothell, WA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Monroe, WA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Seattle, WA</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Kokomo, IN (**soon)</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Noblesville, IN (**soon)</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Beltsville, MD (**soon)</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">College Park, MD (**soon)</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Grand Rapids, MI (**soon)</p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Wyoming, MI (**soon)</p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Leander, TX (**soon)</p>
                                </div>
                            </div>


        <!-- desktop screens-->
                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Montgomery, AL</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Mesa, AZ</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Phoenix, AZ</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Concord, CA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Hayward, CA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">San Bruno, CA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">San Leandro, CA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Tracy, CA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">New Haven, CT</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Bradenton, FL</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Jacksonville, FL</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Alpharetta, GA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Atlanta, GA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Indianapolis, IN</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Charlotte, NC</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Austin, TX</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">College Station, Tx</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Dallas, TX</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Houston, TX</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Bothell, WA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Monroe, WA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Seattle, WA</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Kokomo, IN (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Noblesville, IN (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Beltsville, MD (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">College Park, MD (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Grand Rapids, MI (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Wyoming, MI (**soon)</p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Leander, TX (**soon)</p>
                                    </div>
                                </div>

                        </div>

                    </div>
                </div>



<!-- second green bar--> 
                <h5 style="margin-top: 7rem">without leaving Zillow</h5>
                <div class="bar"></div>

                <div class="paragraph">
                    <p>Say goodbye to extracting Zillow data manually, and entering property details in your Excel spreadsheet.</p>
                    <p style="margin-top: 10px">Get average asking rent in the neighborhood and returns in one click...</p>
                    <p>... and sort by price, rent and returns to screen ONLY the best listings.</p>
                </div>
            </div>

            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-5 col-md-12">
                    <img src="../resources/template00.webp" alt="cash flow rental income">
                </div>

                <div class="col-lg-6 col-md-12 services-content" style="padding-right: 0">

                    <h6 style="margin-top: 1rem">Why property investors love DoorHacker:</h6>

                    <div class="d-block d-sm-none">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="box">
                                    <vue-feather type="code"></vue-feather> Works with Zillow filters
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="box">
                                    <vue-feather type="layout"></vue-feather> Export data to Excel
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="box">
                                    <vue-feather type="pen-tool"></vue-feather> Automatically get average asking rent in the area, and implied returns
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="box">
                                    <vue-feather type="message-circle"></vue-feather> Sort listings by price, rent and auto-calculated returns
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="d-none d-sm-block">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="box" style="padding-right: 5px; padding-left: 5px">
                                    <vue-feather type="code"></vue-feather>  Works with Zillow filters
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="box" style="padding-right: 5px; padding-left: 5px">
                                    <vue-feather type="layout"></vue-feather> Export data to Excel
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="box" style="padding-right: 5px; padding-left: 5px">
                                <vue-feather type="pen-tool"></vue-feather> Automatically get average asking rent in the area, and implied returns
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="box" style="padding-right: 5px; padding-left: 5px">
                                    <vue-feather type="message-circle"></vue-feather> Sort listings by price, rent and auto-calculated returns
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row service-action-btns" style="margin-top: 5rem">
                <div class="col-xl-7 col-lg-9">
                    <div class="row justify-content-center">
                        <div class="col-sm-8 col-10">
                            <a href="https://chrome.google.com/webstore/detail/doorhacker-cash-flow-prop/hodlhboocepdocgnfiidcjhhnfnadmoe?hl=en&authuser=0" class="btn btn-primary btn-register btn-profile">&#128200; DoorHacker Demo &#x2192;</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="website-example funfacts-area">

                <img src="../resources/map.png" alt="real estate cash flow analysis" class="map-bg" style="left: -50%">
                <div class="shape2-services rotateme"><img alt="properties real estate cash flow analysis" src="../resources/shape2.svg"></div>
                <div class="shape3-services"><img alt="zillow cash flow analysis" src="../resources/shape3.svg" ></div>
                <div class="shape4-services"><img alt="zillow extension" src="../resources/shape4.svg" ></div>
                <div class="shape5-services"><img alt="zillow chrome extension" src="../resources/shape5.png" ></div>
            </div>
        </div>
    </section>
    <!-- End Design & Development Services -->
</template>

<script>
export default {
    name: 'ServicesZillow'
}
</script>
