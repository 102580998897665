<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-18></blog-post-18>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost18 from '../blog-components/BlogPost18';


export default {
  name: 'BlogPostView18',
  components: {
    BlogPost18,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Top 21 US Real Estate Investment Destinations for 2023",
      meta: [
        { vmid: 'description', name: 'description', content:  "Real estate investment continues to be a lucrative opportunity for investors seeking long-term financial growth. The United States offers a diverse range of markets, each with its own unique set of advantages and potential returns. As we embark on the year 2023, it's essential to explore the top real estate investment destinations that promise potential growth, stability, and profitability."},
      ]
    }
  }
}
</script>
