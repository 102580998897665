<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-7></blog-success-7>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess7 from '../blog-components/BlogSuccess7';


export default {
  name: 'BlogSuccessView7',
  components: {
    BlogSuccess7,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: My Portfolio of 13 Properties Generating $7,500 Monthly",
      meta: [
        { vmid: 'description', name: 'description', content:  "Intrigued by his friend's fearlessness in navigating the real estate market, John asked about any concerns he might have had regarding timing, location, or price. To John's surprise, his friend confidently stated that what truly mattered to him was the cash flow return, rather than the purchase price of the property."},
      ]
    }
  }
}
</script>
