<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-4></blog-success-4>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess4 from '../blog-components/BlogSuccess4';


export default {
  name: 'BlogSuccessView4',
  components: {
    BlogSuccess4,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: Unveiling 14 Properties Generating $11k Monthly",
      meta: [
        { vmid: 'description', name: 'description', content:  "Ryan found himself in a situation where he wanted to sell his rental property in Los Angeles. However, he was hesitant due to the substantial capital gains tax he would have to pay. Determined to find a solution, Ryan turned to a 1031 exchange, a tax-deferred strategy. This allowed him to defer the taxes by reinvesting the proceeds into a new property."},
      ]
    }
  }
}
</script>
