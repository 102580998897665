<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderZillow v-else></ExternalHeaderZillow>
    <div>
      <BlogGrid2></BlogGrid2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterZillow v-else></ExternalFooterZillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogGrid2 from "../blog-components/BlogGrid2";


export default {
  name: 'BlogGridView2',
  components: {
    BlogGrid2,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Zillow chrome extension: Analyze returns of each listing",
      meta: [
        { vmid: 'description', name: 'description', content:  'Analyze returns of Zillow listings in one click and screen properties with the highest returns. DoorHacker - Property Returns Analyzer for Smart Investors. Discover the power of data-driven real estate investing with DoorHacker to easily find cash flow opportunities. Make informed investment decisions in just a few clicks. Say goodbye to manual data extraction from Zillow and tedious property analysis.'},
      ]
    }
  }
}
</script>
