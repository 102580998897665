import VueRouter from 'vue-router';
// Pages

import HomeZillow from "./views/ideas/zillow/HomeZillow";
import OrderConfirmation from "./views/ideas/zillow/OrderConfirmation";
//import FeedbackConfirmation from "./views/ideas/zillow/FeedbackConfirmation";

//import BlogGridView1 from "./views/ideas/zillow/blog-views/BlogGridView1";
import BlogGridView2 from "./views/ideas/zillow/blog-views/BlogGridView2";
import BlogGridView3 from "./views/ideas/zillow/blog-views/BlogGridView3";

import BlogPostView1 from "./views/ideas/zillow/blog-views/BlogPostView1";
import BlogPostView2 from "./views/ideas/zillow/blog-views/BlogPostView2";
import BlogPostView3 from "./views/ideas/zillow/blog-views/BlogPostView3";
import BlogPostView4 from "./views/ideas/zillow/blog-views/BlogPostView4";
import BlogPostView5 from "./views/ideas/zillow/blog-views/BlogPostView5";
import BlogPostView6 from "./views/ideas/zillow/blog-views/BlogPostView6";
import BlogPostView7 from "./views/ideas/zillow/blog-views/BlogPostView7";
import BlogPostView8 from "./views/ideas/zillow/blog-views/BlogPostView8";
import BlogPostView9 from "./views/ideas/zillow/blog-views/BlogPostView9";
import BlogPostView10 from "./views/ideas/zillow/blog-views/BlogPostView10";
import BlogPostView11 from "./views/ideas/zillow/blog-views/BlogPostView11";
import BlogPostView12 from "./views/ideas/zillow/blog-views/BlogPostView12";
import BlogPostView13 from "./views/ideas/zillow/blog-views/BlogPostView13";
import BlogPostView14 from "./views/ideas/zillow/blog-views/BlogPostView14";
import BlogPostView15 from "./views/ideas/zillow/blog-views/BlogPostView15";
import BlogPostView16 from "./views/ideas/zillow/blog-views/BlogPostView16";
import BlogPostView17 from "./views/ideas/zillow/blog-views/BlogPostView17";
import BlogPostView18 from "./views/ideas/zillow/blog-views/BlogPostView18";

import BlogSuccessView1 from "./views/ideas/zillow/blog-views/BlogSuccessView1";
import BlogSuccessView2 from "./views/ideas/zillow/blog-views/BlogSuccessView2";
import BlogSuccessView3 from "./views/ideas/zillow/blog-views/BlogSuccessView3";
import BlogSuccessView4 from "./views/ideas/zillow/blog-views/BlogSuccessView4";
import BlogSuccessView5 from "./views/ideas/zillow/blog-views/BlogSuccessView5";
import BlogSuccessView6 from "./views/ideas/zillow/blog-views/BlogSuccessView6";
import BlogSuccessView7 from "./views/ideas/zillow/blog-views/BlogSuccessView7";
import BlogSuccessView8 from "./views/ideas/zillow/blog-views/BlogSuccessView8";

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: HomeZillow, name: 'Home' },
      { path: '/confirmation', component: OrderConfirmation },
      //{ path: '/feedback-confirmation', component: FeedbackConfirmation },

      //{ path: '/blog/1', component: BlogGridView1 },
      { path: '/blog/1', component: BlogGridView2 },
      { path: '/blog/2', component: BlogGridView3 },

      { path: '/real-estate-investing-best-practices', component: BlogPostView1 },
      { path: '/decoding-1-percent-rule', component: BlogPostView2 },
      { path: '/costly-mistakes-in-real-estate', component: BlogPostView3 },
      { path: '/fresh-approach-to-real-estate', component: BlogPostView4 },
      { path: '/real-estate-expert-tips', component: BlogPostView5 },
      { path: '/how-to-start-in-real-estate', component: BlogPostView6 },
      { path: '/5-succesfull-strategies-real-estate-investing', component: BlogPostView7 },
      { path: '/best-real-estate-markets-utah', component: BlogPostView8 },
      { path: '/best-real-estate-markets-nevada', component: BlogPostView9 },
      { path: '/best-real-estate-markets-washington', component: BlogPostView10 },
      { path: '/best-real-estate-markets-south-carolina', component: BlogPostView11 },
      { path: '/best-real-estate-markets-north-carolina', component: BlogPostView12 },
      { path: '/best-real-estate-markets-georgia', component: BlogPostView13 },
      { path: '/best-real-estate-markets-arizona', component: BlogPostView14 },
      { path: '/best-real-estate-markets-florida', component: BlogPostView15 },
      { path: '/best-real-estate-markets-indiana', component: BlogPostView16 },
      { path: '/best-real-estate-markets-texas', component: BlogPostView17 },
      { path: '/top21-real-estate-destinations', component: BlogPostView18 },

      { path: '/success-story-balancing-project-management-and-real-estate', component: BlogSuccessView1 },
      { path: '/success-story-software-engineer-buy-and-hold-real-estate', component: BlogSuccessView2 },
      { path: '/success-story-product-designer-journey-to-real-estate', component: BlogSuccessView3 },
      { path: '/success-story-14-properties-generating-$11k-monthly', component: BlogSuccessView4 },
      { path: '/success-story-maximize-returns', component: BlogSuccessView5 },
      { path: '/success-story-acquired-12-profitable-investments', component: BlogSuccessView6 },
      { path: '/success-story-13-properties-generating-$7500-monthly', component: BlogSuccessView7 },
      { path: '/success-story-journey-$0-to-$20000', component: BlogSuccessView8 },
      
      // otherwise redirect to home
      { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    next();
})
