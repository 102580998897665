<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-3></blog-success-3>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess3 from '../blog-components/BlogSuccess3';


export default {
  name: 'BlogSuccessView3',
  components: {
    BlogSuccess3,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: A Product Designer's Journey to Real Estate Passive Income",
      meta: [
        { vmid: 'description', name: 'description', content:  "After six months of thorough analysis of properties on Zillow, Clara pinpointed a promising single-family rental house located in Memphis, Tennessee. She identified its great cash flow potential thanks to DoorHacker Chrome extension."},
      ]
    }
  }
}
</script>
