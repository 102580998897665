<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
    <blog-success-2></blog-success-2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess2 from '../blog-components/BlogSuccess2';


export default {
  name: 'BlogSuccessView2',
  components: {
    BlogSuccess2,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: Software engineer pursuing buy-and-hold strategy in real estate",
      meta: [
        { vmid: 'description', name: 'description', content:  "By focusing on areas experiencing positive economic indicators, Justin aimed to tap into regions with potential for future growth. Such markets offer stability and increased demand for rental properties, which can contribute to long-term success."},
      ]
    }
  }
}
</script>
