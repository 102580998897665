<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >Real Estate Investing Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="z-index: 100;top:20px" class="shape1"><img src="../resources/shape1.png" alt="zillow export to excel"></div>
			<div style="z-index: 100;" class="shape8 rotateme"><img src="../resources/shape2.svg" alt="zillow login"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="pull data from zillow"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-utah">
									<img src="../resources/blog/blog8-1x1.webp" alt="where to invest in Utah">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 22, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-utah">Unlocking Utah's Real Estate Potential</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Investing in real estate in Utah offers promising opportunities due to its strong economy, population growth, and diverse market. Cities like Salt Lake City, Provo, and Park City present attractive prospects for investors.</p>

								<a href="/best-real-estate-markets-utah" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/5-succesfull-strategies-real-estate-investing">
									<img src="../resources/blog/blog7-1x1.webp" alt="zillow to excel extension">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 17, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/5-succesfull-strategies-real-estate-investing">5 Easy Strategies for Successful Real Estate Investments</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Investing in real estate has long been recognized as a profitable venture, providing individuals with a reliable source of passive income and the potential for substantial long-term wealth. However, like any investment, real estate comes...</p>

								<a href="/5-succesfull-strategies-real-estate-investing" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather></a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/how-to-start-in-real-estate">
									<img src="../resources/blog/blog6-1x1.webp" alt="start investing in real estate">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 8, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/how-to-start-in-real-estate">How to Start Investing in Real Estate</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Real estate investment has long been regarded as a pathway to wealth and financial independence. However, if you're new to the world of real estate, getting started can feel daunting. The good news is that...</p>

								<a href="/how-to-start-in-real-estate" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/real-estate-expert-tips">
									<img src="../resources/blog/blog5-1x1.webp" alt="tips to invest in real estate">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 3, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/real-estate-expert-tips">The Ultimate Property Investment Handbook</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Investing in properties can be a lucrative venture, providing individuals with a steady stream of income and the potential for long-term wealth. However, navigating the complex world of property investment requires...</p>

								<a href="/real-estate-expert-tips" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/fresh-approach-to-real-estate">
									<img src="../resources/blog/blog4-1x1.webp" alt="my first investment property">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 20, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/fresh-approach-to-real-estate">A Fresh Approach to Real Estate Investment</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>When it comes to real estate investment, many individuals follow the same conventional path, investing in familiar property types and sticking to traditional strategies. However, in a competitive market, it's crucial...</p>

								<a href="/fresh-approach-to-real-estate" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/costly-mistakes-in-real-estate">
									<img src="../resources/blog/blog3-1x1.webp" alt="mistakes real estate">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 15, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/costly-mistakes-in-real-estate">The 5 Biggest Pitfalls in Property Investing for 2023</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Property investing can be a rewarding venture, offering the potential for financial growth and long-term wealth. However, like any investment, it's crucial to navigate the market with caution and avoid common pitfalls that can lead...</p>

								<a href="/costly-mistakes-in-real-estate" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/decoding-1-percent-rule">
									<img src="../resources/blog/blog2-1x1.webp" alt="1% real estate rule">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 12, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/decoding-1-percent-rule">Decoding the 1% Rule in Real Estate Investment</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Real estate investment comes with a plethora of strategies and formulas to evaluate the potential profitability of a property. One such fundamental principle is the 1% rule. The 1% rule serves as a quick and simplified guideline for real estate...</p>

								<a href="/decoding-1-percent-rule" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/real-estate-investing-best-practices">
									<img src="../resources/blog/blog1-1x1.webp" alt="best practices to invest in real estate">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 11, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/real-estate-investing-best-practices">Pros, Cons, and Best Practices of Real Estate Investment</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Real estate investment has long been regarded as a viable pathway to financial growth and long-term wealth. However, like any investment, it comes with its own set of pros and cons.</p>

								<a href="/real-estate-investing-best-practices" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/1">Prev</a></li>
									<li class="page-item "><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item active"><a class="page-link" href="/blog/2">2</a></li>
									<!--<li class="page-item active"><a class="page-link" href="/blog/3">3</a></li>-->
								</ul>
							</nav>
						</div>
					</div>

				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid3'
}
</script>
