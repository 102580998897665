<template>

    <form id="signupZillow" @submit.prevent="signupEmail()" class="mt-4">
        <div class="row">
            <div class="col-sm-8">
                <input type="email"  name="emailZillow" id="emailZillow" class="form-control"  v-model="email" placeholder="Enter your email address" style="border-radius: 4px;">
                <p style="font-size: 10px; color:#495057">Sign up for DoorHacker Zillow Extension (DEMO)</p>
                <div class="mt-4">
                    <p style="color: #3b2d9b; font-weight: 500; margin-bottom: 20px; font-size: 14px;">{{ message }}</p>
                </div>
            </div>
            <div class="col-4 offset-sm-0 offset-4" style="padding: 0px">
                <button type="submit" class="btn btn-primary">Sign up</button>
            </div>
        </div>
    </form>

</template>


<script>

    export default {
        name: 'SignupZillow',
        data() {
            return {
                email: '',
                message: ''
            }
        },
        methods: {
            signupEmail() {
                if(!this.validateEmail(this.email)) {
                    this.message = "The email address is badly formatted.";
                } else {
                  const url = `/api/contact/landing`;
                  const requestOptions = {
                    method: "POST",
                    headers: {"Content-Type" : "application/json"},
                    body: JSON.stringify({ name: 'DEMO user', email: this.email, message: 'Doorhacker signup' })
                  };
                  fetch(url, requestOptions).then(() => {
                    this.message = "🎉 We will e-mail you back in 24h 🎉";
                  })
                }
            },
            validateEmail(email) {
                var re = /\S+@\S+\.\S+/;
                return re.test(String(email).toLowerCase());
            }
        }
    }

</script>
