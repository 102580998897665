<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-9></blog-post-9>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost9 from '../blog-components/BlogPost9';


export default {
  name: 'BlogPostView9',
  components: {
    BlogPost9,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Nevada: Real Estate Investment Guide",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Nevada offers promising prospects due to its growing economy, population influx, and diverse market. Cities like Las Vegas, Reno, and Henderson present attractive opportunities for investors. Las Vegas, with its thriving tourism industry and strong rental demand, provides a range of investment options in residential and commercial properties."},
      ]
    }
  }
}
</script>
