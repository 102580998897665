<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-4></blog-post-4>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost4 from '../blog-components/BlogPost4';


export default {
  name: 'BlogPostView4',
  components: {
    BlogPost4,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Diverging from the Crowd: A Fresh Approach to Real Estate Investment",
      meta: [
        { vmid: 'description', name: 'description', content:  "When it comes to real estate investment, many individuals follow the same conventional path, investing in familiar property types and sticking to traditional strategies. However, in a competitive market, it's crucial to find ways to stand out and uncover unique opportunities that others might overlook."},
      ]
    }
  }
}
</script>
