<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-15></blog-post-15>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost15 from '../blog-components/BlogPost15';


export default {
  name: 'BlogPostView15',
  components: {
    BlogPost15,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Florida: Discover the Hottest Real Estate Markets",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Florida can be highly rewarding due to several factors. The state's robust tourism industry, growing population, and favorable climate make it an attractive destination for residents and vacationers alike. Florida offers diverse real estate opportunities."},
      ]
    }
  }
}
</script>
