<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-1></blog-success-1>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess1 from '../blog-components/BlogSuccess1';


export default {
  name: 'BlogSuccessView1',
  components: {
    BlogSuccess1,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: Balancing Project Management and Real Estate Investing",
      meta: [
        { vmid: 'description', name: 'description', content:  "Capitalizing on the advantages offered by the Indianapolis market, David leveraged the ten reasons he identified for investing in the city. Each property he acquired went through a thorough analysis, considering factors such as location, rental demand, projected cash flow, and potential for appreciation."},
      ]
    }
  }
}
</script>
