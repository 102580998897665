<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-17></blog-post-17>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost17 from '../blog-components/BlogPost17';


export default {
  name: 'BlogPostView17',
  components: {
    BlogPost17,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Texas: Unveiling the Best Real Estate Opportunities",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Texas offers a wealth of opportunities due to its strong economy, population growth, diverse real estate market, favorable business environment, affordable housing, and resilience in the face of challenges. Major cities like Houston, Dallas, and Austin present various investment options."},
      ]
    }
  }
}
</script>
