<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-8></blog-success-8>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess8 from '../blog-components/BlogSuccess8';


export default {
  name: 'BlogSuccessView8',
  components: {
    BlogSuccess8,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: My Journey from $0 to Earning $20,000 per Month in Just 6 Months",
      meta: [
        { vmid: 'description', name: 'description', content:  "Seeking a streamlined investment approach, Anna turned her attention to turn-key properties. These properties undergo extensive renovations, transforming them into pristine, like-new conditions. Following the renovations, qualified tenants are carefully placed, and ongoing property management services are provided. This turn-key strategy minimizes the involvement required from investors while maximizing potential returns."},
      ]
    }
  }
}
</script>
