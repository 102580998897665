<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-6></blog-success-6>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess6 from '../blog-components/BlogSuccess6';


export default {
  name: 'BlogSuccessView6',
  components: {
    BlogSuccess6,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: How We Acquired 12 Profitable Investments Earning $8,700 Monthly",
      meta: [
        { vmid: 'description', name: 'description', content:  "The thought of leaving his wife without the financial security they had worked so hard to build together was a distressing prospect. Determined to rectify the situation, he and Danielle sought a solution that would safeguard their future. With their determination fueling their quest for a secure future, Chris and Danielle turned their attention to San Antonio, Texas. Recognizing the city's burgeoning potential, they saw an opportunity to capitalize on the growing influx of tech workers."},
      ]
    }
  }
}
</script>
