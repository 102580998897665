<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-7></blog-post-7>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost7 from '../blog-components/BlogPost7';


export default {
  name: 'BlogPostView7',
  components: {
    BlogPost7,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "5 Easy Strategies for Successful Real Estate Investments",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate has long been recognized as a profitable venture, providing individuals with a reliable source of passive income and the potential for substantial long-term wealth. However, like any investment, real estate comes with its own set of challenges and risks."},
      ]
    }
  }
}
</script>
