<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-11></blog-post-11>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost11 from '../blog-components/BlogPost11';


export default {
  name: 'BlogPostView11',
  components: {
    BlogPost11,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "South Carolina: Unveiling Real Estate Investment Hotspots",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in South Carolina presents an appealing opportunity due to its growing economy, favorable business climate, and diverse market. Cities like Charleston, Greenville, and Columbia offer attractive prospects for investors. Charleston, with its historic charm and strong tourism industry, provides opportunities in vacation rentals and commercial properties."},
      ]
    }
  }
}
</script>
