<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-6></blog-post-6>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost6 from '../blog-components/BlogPost6';


export default {
  name: 'BlogPostView6',
  components: {
    BlogPost6,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "How to Start Investing in Real Estate (7 Effective Methods)",
      meta: [
        { vmid: 'description', name: 'description', content:  "Real estate investment has long been regarded as a pathway to wealth and financial independence. However, if you're new to the world of real estate, getting started can feel daunting. The good news is that with the right knowledge and approach, anyone can enter the realm of real estate investing."},
      ]
    }
  }
}
</script>
