<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-success-5></blog-success-5>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogSuccess5 from '../blog-components/BlogSuccess5';


export default {
  name: 'BlogSuccessView5',
  components: {
    BlogSuccess5,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Success Story: Our Success Story with 6 Properties Yielding $3,000+ per Month",
      meta: [
        { vmid: 'description', name: 'description', content:  "As Kathy evaluated her assets, she couldn't ignore the financial strain caused by their beach house in North Carolina, which incurred a monthly loss of $1,200. Despite the unfavorable timing, Kathy made the difficult decision to sell the property, even if it meant taking a loss. The sale, however, yielded approximately $160,000 in cash, providing them with a valuable resource to pursue new opportunities."},
      ]
    }
  }
}
</script>
