<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >Real Estate Investing Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="z-index: 100;top:20px" class="shape1"><img src="../resources/shape1.png" alt="zillow rent returns"></div>
			<div style="z-index: 100;" class="shape8 rotateme"><img src="../resources/shape2.svg" alt="rental cashflow calculator"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="zillow data exporter"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">

				<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-software-engineer-buy-and-hold-real-estate">
									<img src="../resources/blog/blog17-1x1.webp" alt="Zillow cash flow returns">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-software-engineer-buy-and-hold-real-estate">Software Engineer Pursuing Buy-and-Hold Strategy in Real Estate</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Real estate investing can be a lucrative venture, but it requires careful planning, research, and strategy. Justin, a software engineer and an aspiring real estate investor, embarked...</p>

								<a href="/success-story-software-engineer-buy-and-hold-real-estate" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-balancing-project-management-and-real-estate">
									<img src="../resources/blog/blog16-1x1.webp" alt="zillow to excel">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-balancing-project-management-and-real-estate">Balancing Project Management and Real Estate Investing</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>While David recognized the potential of real estate investing to build wealth and generate passive income, he decided to strike a balance between his successful project management career and his passion for real estate.</p>

								<a href="/success-story-balancing-project-management-and-real-estate" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-florida">
									<img src="../resources/blog/blog15-1x1.webp" alt="where to invest in Florida">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 14, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-florida">Discover the Hottest Real Estate Markets in Florida</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Investing in real estate in Florida can be highly rewarding due to several factors. The state's robust tourism industry, growing population, and favorable climate make it an attractive destination for residents and vacationers alike.</p>
								
								<a href="/best-real-estate-markets-florida" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-arizona">
									<img src="../resources/blog/blog14-1x1.webp" alt="where to invest in Arizona">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 13, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-arizona">Investing in Arizona Real Estate</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Investing in real estate in Arizona offers attractive prospects due to its strong economy, population growth, diverse market, and affordable housing. The state's robust job market and favorable business climate drive demand for housing, presenting opportunities...</p>

								<a href="/best-real-estate-markets-arizona" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-georgia">
									<img src="../resources/blog/blog13-1x1.webp" alt="where to invest in Georgia">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 6, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-georgia">Georgia's Real Estate Gems</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Investing in real estate in Georgia, presents a promising opportunity due to its strong economy, population growth, and diverse market. Cities like Atlanta, Savannah, and Augusta offer attractive prospects for investors.</p>

								<a href="/best-real-estate-markets-georgia" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-north-carolina">
									<img src="../resources/blog/blog12-1x1.webp" alt="where to invest in North Carolina">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 2, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-north-carolina">Unearthing Promising Real Estate Markets in North Carolina</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Investing in real estate in North Carolina presents a compelling opportunity due to its strong economy, population growth, and diverse market. Cities like Charlotte, Raleigh, and Asheville...</p>

								<a href="/best-real-estate-markets-north-carolina" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-south-carolina">
									<img src="../resources/blog/blog11-1x1.webp" alt="where to invest in South Carolina">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 1, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-south-carolina">Unveiling South Carolina's Real Estate Investment Hotspots</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Investing in real estate in South Carolina presents an appealing opportunity due to its growing economy, favorable business climate, and diverse market.</p>

								<a href="/best-real-estate-markets-south-carolina" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-washington">
									<img src="../resources/blog/blog10-1x1.webp" alt="where to invest in Washington">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 30, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-washington">Navigating Washington's Real Estate Opportunities</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Investing in real estate in Washington state presents a compelling opportunity due to its strong economy, population growth, and diverse market. Cities like Seattle, Bellevue, and Spokane offer favorable prospects.</p>

								<a href="/best-real-estate-markets-washington" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/best-real-estate-markets-nevada">
									<img src="../resources/blog/blog9-1x1.webp" alt="where to invest in Nevada">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 23, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/best-real-estate-markets-nevada">Nevada Real Estate Investment Guide</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Investing in real estate in Nevada offers promising prospects due to its growing economy, population influx, and diverse market. Cities like Las Vegas, Reno, and Henderson present attractive opportunities for investors.</p>

								<a href="/best-real-estate-markets-nevada" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<!--<li class="page-item"><a class="page-link" href="/blog/1">Prev</a></li>-->
									<li class="page-item active"><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<!--<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>-->
									<li class="page-item"><a class="page-link" href="/blog/2">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid2'
}
</script>
