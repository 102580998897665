<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-5></blog-post-5>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost5 from '../blog-components/BlogPost5';


export default {
  name: 'BlogPostView5',
  components: {
    BlogPost5,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Expert Tips: The Ultimate Property Investment Handbook",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in properties can be a lucrative venture, providing individuals with a steady stream of income and the potential for long-term wealth. However, navigating the complex world of property investment requires a comprehensive understanding of the market, industry trends, and effective strategies."},
      ]
    }
  }
}
</script>
