<template>
    <!-- Start Pricing Area -->
    <section class="pricing-area ptb-80" style="background: #F9F6F6">
        <div id="pricing"/>
        <div class="container">
            <div class="section-title">
                <h2>Pricing Plans</h2>
                <div class="bar"></div>
                <div class="paragraph">
                    <p>Try DoorHacker DEMO for <a href="https://chrome.google.com/webstore/detail/doorhacker-cash-flow-prop/hodlhboocepdocgnfiidcjhhnfnadmoe?hl=en&authuser=0" style="font-weight: 600">FREE</a>.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table">
                        <div class="pricing-header">
                            <h3>DEMO</h3>
                        </div>

                        <div class="price">
                            <span><sup></sup>FREE<span></span></span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active">10 properties analyzed per search</li>
                                <li class="active">10 properties per Excel export</li>
                                <li class="active">Request additional cities</li>
                                <li>Sorting by price, rent, and returns</li>
                                <li>Edit rent inputs (*** to come)</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="https://chrome.google.com/webstore/detail/doorhacker-cash-flow-prop/hodlhboocepdocgnfiidcjhhnfnadmoe?hl=en&authuser=0" class="btn btn-primary">TRY DEMO</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table active-plan">
                        <div class="pricing-header">
                            <h3>Pro Plan</h3>
                        </div>

                        <div class="price">
                            <span><sup></sup>$29<span> monthly</span></span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active">800 properties analyzed per search</li>
                                <li class="active">800 properties per Excel export</li>
                                <li class="active">City requests prioritized</li>
                                <li class="active">Sorting by price, rent, and returns</li>
                                <li class="active">Edit rent inputs (*** to come)</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="https://buy.stripe.com/14kcON0E18b0byE3ce" class="btn btn-primary">UPGRADE</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="shape7"><img src="../resources/shape4.svg" alt="zillow cash flow returns"></div>
    </section>
    <!-- End Pricing Area -->
</template>

<script>
export default {
    name: 'PricingZillow'
}
</script>
