<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-14></blog-post-14>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost14 from '../blog-components/BlogPost14';


export default {
  name: 'BlogPostView14',
  components: {
    BlogPost14,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Arizona: Investing in Real Estate",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Arizona offers attractive prospects due to its strong economy, population growth, diverse market, and affordable housing. The state's robust job market and favorable business climate drive demand for housing, presenting opportunities for appreciation and rental income."},
      ]
    }
  }
}
</script>
