<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderZillow v-else></HeaderZillow>
    <div>
      <BannerZillow></BannerZillow>
      <ServicesZillow></ServicesZillow>
      <WorksZillow></WorksZillow>
      <PricingZillow></PricingZillow>
      <ContactZillow></ContactZillow>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterZillow v-else></FooterZillow>
  </div>

</template>


<script>
  import BannerZillow from './components/BannerZillow';
  import ServicesZillow from './components/ServicesZillow';
  import PricingZillow from './components/PricingZillow';
  import WorksZillow from "./components/WorksZillow";
  import ContactZillow from './components/ContactZillow';
  import HeaderZillow from "./components/HeaderZillow";
  import FooterZillow from "./components/FooterZillow";

  export default {
    name: 'HomeZillow',
    data() {
      return {
        currentUrl: '',
      };
    },
    components: {
      BannerZillow,
      ServicesZillow,
      PricingZillow,
      WorksZillow,
      ContactZillow,
      HeaderZillow,
      FooterZillow
    },
    mounted() {
      setTimeout(function(){
        if (this.$route.params.location === 'Home') {
        this.$scrollTo('#home');
      } else if (this.$route.params.location === 'Solutions') {
        this.$scrollTo('#solutions');
      } else if (this.$route.params.location === 'Pricing') {
        this.$scrollTo('#pricing');
      } else if (this.$route.params.location === 'Contact') {
        this.$scrollTo('#contact');
      }

      }.bind(this), 100)
    },
    metaInfo() {
      return {
        title: "Find Cash Flow on Zillow: Sort by highest returns and rents",
        meta: [
          { vmid: 'description', name: 'description', content:  'Spot properties with the highest returns. Sort filtered Zillow listings by return, rent and price. Export data to Excel.'},
        ]
      }
    }
  }
</script>

