<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-1></blog-post-1>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost1 from '../blog-components/BlogPost1';


export default {
  name: 'BlogPostView1',
  components: {
    BlogPost1,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Pros, Cons, and Best Practices of Real Estate Investment",
      meta: [
        { vmid: 'description', name: 'description', content:  "Real estate investment has long been regarded as a viable pathway to financial growth and long-term wealth. However, like any investment, it comes with its own set of pros and cons. Understanding the advantages, disadvantages, and best practices of real estate investment is essential for making informed decisions and maximizing your chances of success."},
      ]
    }
  }
}
</script>
