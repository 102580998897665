<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-3></blog-post-3>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost3 from '../blog-components/BlogPost3';


export default {
  name: 'BlogPostView3',
  components: {
    BlogPost3,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Avoiding Costly Mistakes: The 5 Biggest Pitfalls in Property Investing for 2023",
      meta: [
        { vmid: 'description', name: 'description', content:  "Property investing can be a rewarding venture, offering the potential for financial growth and long-term wealth. However, like any investment, it's crucial to navigate the market with caution and avoid common pitfalls that can lead to costly mistakes. In this article, we'll discuss the five biggest pitfalls in property investing for 2023, providing essential insights to help you safeguard your investments and maximize your chances of success."},
      ]
    }
  }
}
</script>
