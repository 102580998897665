<template>
    <!-- Start Main Banner -->
    <div class="main-banner" style="padding-top: 5rem; margin-top: 2rem; padding-bottom: 3rem">
        <div id="home"/>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-xl-6 col-lg-7">
                            <div class="hero-content">
                                <div class="row">
                                    <div class="col-lg-11">
                                        <h1>Find cash flow on Zillow</h1>
                                        <h5>Compare rents and prices with one click, spot properties with the highest returns</h5>


                                        <div class="tick-section">
                                            <div class="d-block d-sm-none">
                                                <div class="tick-row">
                                                    <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                    <p class="tick-input">Get average asking rent in the neighborhood</p>
                                                </div>
                                            </div>

                                                <div class="d-none d-sm-block">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input">Get average asking rent in the neighborhood</p>
                                                    </div>
                                                </div>

                                            <div class="d-block d-sm-none">
                                                <div class="tick-row">
                                                    <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                    <p class="tick-input">Sort by price, rent and returns</p>
                                                </div>
                                            </div>

                                                <div class="d-none d-sm-block">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input">Sort by price, rent and returns</p>
                                                    </div>
                                                </div>
                                            

                                            <div class="d-block d-sm-none">
                                                <div class="tick-row">
                                                    <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                    <p class="tick-input">Rent/Price and gross annual return calculated</p>
                                                </div>
                                            </div>

                                                <div class="d-none d-sm-block">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input">Rent/Price and gross annual return calculated</p>
                                                    </div>
                                                </div>

                                            <div class="d-block d-sm-none">
                                                <div class="tick-row">
                                                    <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                    <p class="tick-input">Data export to Excel</p>
                                                </div>
                                            </div>

                                                <div class="d-none d-sm-block">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input">Data export to Excel</p>
                                                    </div>
                                                </div>
                                            
                                            <div style="margin-top: 3rem">
                                                <SignupZillow></SignupZillow>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-6 col-lg-5 video" style="margin-top: 6rem">
                            <div class="row">

                                <div class="d-block d-lg-none">
                                    <vue-plyr ref="plyr">
                                        <video
                                                controls
                                                crossorigin
                                                playsinline
                                                poster="../resources/template0.webp" style="background: white;">
                                            <source src="../resources/intro.mp4" type="video/mp4">
                                            Your browser does not support the video tag
                                        </video>
                                    </vue-plyr>
                                </div>

                                <div class="d-none d-lg-block">
                                    <vue-plyr ref="plyr">
                                        <video
                                                controls
                                                crossorigin
                                                playsinline
                                                poster="../resources/template0.webp" style="background: white;">
                                            <source src="../resources/intro.mp4" type="video/mp4">
                                            Your browser does not support the video tag
                                        </video>
                                    </vue-plyr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row banner-action-btns">
                        <div class="col-xl-7 col-lg-9">
                            <div class="row justify-content-center">
                                <div class="col-sm-8 col-10">
                                    <a href="https://chrome.google.com/webstore/detail/doorhacker-cash-flow-prop/hodlhboocepdocgnfiidcjhhnfnadmoe?hl=en&authuser=0" class="btn btn-primary btn-register btn-profile" style="margin-bottom: 8px">&#129351; Add to Chrome &#x2192;</a>
                                    <!--<div class="users">Join 1,126+ property investors on DoorHacker</div>-->
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="shape1"><img src="../resources/shape1.png" alt="zillow cash flow"></div>
        <div class="shape3"><img src="../resources/shape3.svg" alt="rent income estimate"></div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="Zillow cash flow returns"></div>
        <div class="shape5"><img src="../resources/shape5.png" alt="zillow investors"></div>
        <div class="shape6 rotateme"><img src="../resources/shape4.svg" alt="real estate cash flow analysis"></div>
        <div class="shape7"><img src="../resources/shape4.svg" alt="cash on cash return"></div>
        <div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="zillow rent analysis"></div>
    </div>
    <!-- End Main Banner -->
</template>



<script>

import SignupZillow from './SignupZillow';

    export default {
        name: 'BannerZillow',
        data() {
            return {
                fired: false
            }
        },
        components: {
            SignupZillow
        },
        mounted () {
            this.$refs.plyr.player.volume = 0.5;
        }
    }

</script>
