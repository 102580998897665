<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-16></blog-post-16>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost16 from '../blog-components/BlogPost16';


export default {
  name: 'BlogPostView16',
  components: {
    BlogPost16,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Indiana: Exploring Lucrative Real Estate Markets",
      meta: [
        { vmid: 'description', name: 'description', content:  "Investing in real estate in Indiana can be advantageous due to its stable real estate market, affordable property prices, and diverse economic opportunities. Cities like Indianapolis, Fort Wayne, and Bloomington offer promising prospects for real estate investors."},
      ]
    }
  }
}
</script>
