<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-header-zillow v-else></external-header-zillow>
    <div>
      <blog-post-2></blog-post-2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <external-footer-zillow v-else></external-footer-zillow>
  </div>

</template>

<script>
import ExternalHeaderZillow from "../components/ExternalHeaderZillow";
import ExternalFooterZillow from "../components/ExternalFooterZillow";
import BlogPost2 from '../blog-components/BlogPost2';


export default {
  name: 'BlogPostView2',
  components: {
    BlogPost2,
    ExternalHeaderZillow,
    ExternalFooterZillow,
  },
  data() {
    return {
      currentUrl: '',
    };
  },
  metaInfo() {
    return {
      title: "Decoding the 1% Rule: A Key Principle in Real Estate Investment",
      meta: [
        { vmid: 'description', name: 'description', content:  "Real estate investment comes with a plethora of strategies and formulas to evaluate the potential profitability of a property. One such fundamental principle is the 1% rule. The 1% rule serves as a quick and simplified guideline for real estate investors to assess the income potential of a property relative to its purchase price."},
      ]
    }
  }
}
</script>
